var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app"},[_c('AppHeader',{class:_vm.isInMaintenance == 0 && _vm.isProductiveSystem == 0 ? 'bg-warning' : _vm.isInMaintenance == 1 ? 'bg-info' : 'bg-dark',attrs:{"fixed":""}},[_c('SidebarToggler',{staticClass:"d-lg-none",attrs:{"display":"md","mobile":""}}),_c('b-link',{staticClass:"navbar-brand pl-2",attrs:{"to":"/"}},[_c('img',{staticClass:"navbar-brand-full",attrs:{"src":_vm.logoImagePath,"height":"35","alt":"Ibomade Technik"}}),_c('img',{staticClass:"navbar-brand-minimized",attrs:{"src":"/img/brand/logo-ibomade-small.svg","width":"30","height":"30","alt":"Ibomade Technik"}})]),_c('SidebarToggler',{staticClass:"d-md-down-none",attrs:{"display":"lg","defaultOpen":true}}),_c('SystemMaintenanceIndicator',{staticClass:"ml-lg-auto"}),_c('div',{staticClass:"text-center ml-lg-auto"},[(_vm.isProductiveSystem == 0 && _vm.isInMaintenance == 0)?_c('span',[_c('span',{staticStyle:{"font-size":"18px","text-transform":"uppercase"}},[_vm._v("Testsystem")])]):_vm._e()]),_c('b-navbar-nav',{staticClass:"ml-auto"},[_c('DefaultHeaderDropdownAccnt')],1)],1),_c('div',{staticClass:"app-body",on:{"click":_vm.HandleOpenLinks}},[_c('AppSidebar',{attrs:{"fixed":""}},[_c('SidebarHeader',{staticClass:"bg-gray-800"},[(_vm.getCurrentUser)?_c('b-col',{staticClass:"text-center my-4"},[_c('b-avatar',{attrs:{"size":"75px","variant":"success","text":("" + (_vm.getCurrentUser.firstName.charAt(0).toUpperCase()) + (_vm.getCurrentUser.lastName
              .charAt(0)
              .toUpperCase()))}}),_c('div',{staticClass:"user-info mt-3"},[_c('div',{staticClass:"text-light"},[_vm._v(" "+_vm._s(_vm.getCurrentUser.firstName)+" "),_c('strong',[_vm._v(_vm._s(_vm.getCurrentUser.lastName))])]),_c('div',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.getCurrentUser.username))])])],1):_c('b-col',{staticClass:"text-center p-5"},[_c('b-spinner',{attrs:{"variant":"success","type":"grow","label":"Loading..."}})],1)],1),_c('SidebarNav',{attrs:{"data-click":"nav-bar","navItems":_vm.isUserAdmin
            ? _vm.$t('sidebar.adminNav.items')
            : _vm.isUserProjectManager
            ? _vm.$t('sidebar.projectManagerNav.items')
            : _vm.isUserAgency
            ? _vm.$t('sidebar.managerNav.items')
            : _vm.$t('sidebar.userNav.items')}}),_c('SidebarMinimizer')],1),_c('main',{staticClass:"main custom-scroll"},[_c('Breadcrumb',{staticClass:"border-bottom-0 mb-3",attrs:{"list":_vm.list}}),_c('div',{staticClass:"container-fluid pb-5"},[_c('router-view',{key:_vm.$route.fullPath})],1)],1)],1),_c('TheFooter',{staticClass:"fixed-bottom py-2 text-base-dark"},[_c('div',{staticClass:"py-1"},[_c('a',{staticClass:"text-success",attrs:{"href":"https://www.ibomade-technik.de","target":"_blank"}},[_vm._v("ibomade Technik")]),_c('span',{staticClass:"ml-1"},[_vm._v("© 2020 IBOMADE GmbH")])]),_c('div',{staticClass:"ml-lg-auto"},[_c('span',{staticClass:"mr-5"},[_vm._v("Version "+_vm._s(_vm.VERSION))]),_c('span',{staticClass:"mr-1"},[_vm._v("Powered by Mainstream Core")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }